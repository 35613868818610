import { ErrorTag } from "components/ErrorTag/ErrorTag"
import { ImportContext } from "components/ImportComponents/ImportContext"
import { TemplateColumn } from "components/ImportComponents/types"
import React, { useContext, useMemo } from "react"
import { useTrans } from "translations"
import { ValidateCell } from "./ValidateCell"

type Props = {
	sectionKey: string
	column: TemplateColumn
}

export const ValidateColumn: React.FC<Props> = ({ sectionKey, column }) => {
	const { t } = useTrans()
	const { validationState, mappingState } = useContext(ImportContext)

	const columnValidation = useMemo(
		() => validationState[sectionKey]?.[column.key],
		[validationState, sectionKey, column]
	)

	const nrErrors = useMemo(
		() => columnValidation?.filter(el => el.error).length || 0,
		[columnValidation]
	)

	const cellsView = useMemo(
		() =>
			columnValidation?.map((cell, i) => (
				<ValidateCell
					key={`${sectionKey}-${column.key}-${i}`}
					sectionKey={sectionKey}
					cell={cell}
					cellIndex={i}
					column={column}
				/>
			)),
		[columnValidation, sectionKey, column]
	)

	const isRequired = useMemo(
		() => column.isRequired || column.isRequiredFunction?.(mappingState),
		[column, mappingState]
	)

	return (
		<div className="flex flex-col">
			<div className="h-12 p-2.5 text-sm font-medium border-b border-grey3 flex sticky top-0 bg-white z-[9]">
				{t(column.title)} {isRequired && " *"}
				{nrErrors > 0 && (
					<div className="ml-2">
						<ErrorTag nrErrors={nrErrors} />
					</div>
				)}
			</div>

			{cellsView}
		</div>
	)
}
