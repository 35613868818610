import { BarChart } from "components/BarChart"
import { getTimeFrame } from "components/pageCards/filterSort/filterTimeFrame"
import { useEffect, useMemo, useState } from "react"
import { useTerminalsState } from "States/Terminals"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import { Terminal, TerminalAggregate } from "Utils/api/datawarehouse/responseTypes"
import { FetchedWasteTypes } from "Utils/api/sanity/types"
import { ComparisonWasteTypeDataInterface } from "../overview.interface"
import { CategoryNameFilters } from "Utils/api/datawarehouse/types"
import { orderBy, truncate } from "lodash"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { EmptyView } from "components/EmptyView/EmptyView"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { FilterSort } from "components/pageCards/filterSort/types"
import { Card, CardComponentFilterLocation } from "components/pageCards/card"
import {
	FilterTimeFrameValue,
	createTimeFilters,
} from "components/pageCards/filterSort/filterCreators"
import { WasteTypeConfig, useWasteTypeConfig } from "pages/configuration/useWasteTypeConfig"
import { COLORS, getNewColor } from "pages/customer/dashboard/functions/pieChartData"
import { useConfig } from "api/hooks/useConfig"
import { useHomeLoadingState } from "pages/home/loadingState"

const createWasteKgPerTenantBarData = (
	realEstate: Terminal | undefined,
	aggregatedRealEstate: TerminalAggregate | undefined,
	wasteTypes: FetchedWasteTypes | undefined,
	wasteTypeConfig: WasteTypeConfig[]
): ComparisonWasteTypeDataInterface[] => {
	if (!realEstate || !aggregatedRealEstate) return []

	let curColors = [...COLORS]
	const data: ComparisonWasteTypeDataInterface[] =
		realEstate.wasteTypes?.reduce((acc: ComparisonWasteTypeDataInterface[], wasteType) => {
			const defaultAggregate = { weight: { quantity: 0, unit: "kg" }, share: 0 }
			const aggregateWasteType =
				aggregatedRealEstate.wasteTypes.find(wt => wt.code === wasteType.code) ?? defaultAggregate

			if (wasteType.share < 0.03) {
				return acc
			}

			const customWasteType = wasteTypeConfig.find(wtc => wtc.wasteTypeCode === wasteType.code)

			const [color, newColors] = getNewColor(curColors)
			curColors = newColors
			return [
				...acc,
				{
					value: wasteType.weight.quantity / realEstate.totalCustomers,
					average: aggregateWasteType.weight.quantity / aggregatedRealEstate.totalCustomers,
					name:
						customWasteType?.name ||
						wasteTypes?.find(wt => wt.id === wasteType.code)?.name ||
						wasteType.code,
					color: customWasteType?.color || color || "",
				},
			]
		}, []) || []

	const charactersToShow = 100 / data.length

	const truncatedBarChartData: ComparisonWasteTypeDataInterface[] = data.map(el => ({
		...el,
		fullName: el.name,
		name: truncate(el.name, { length: charactersToShow }),
	}))

	return orderBy(truncatedBarChartData, "name", "asc")
}

const defaultPeriod = FilterTimeFrameValue.LAST_30_DAYS

export const ComparisonWastePerTenant = () => {
	const [{ period: [selectedPeriod] = [] }, setActiveOptions] = useState<ActiveOptions<"period">>(
		{} as any
	)
	const { wasteTypes } = useCommonEntitiesStore()
	const filters: FilterSort[] = [createTimeFilters({ defaultValue: defaultPeriod })]

	const { currentTerminal } = useTerminalsState()
	const { wasteTypeConfig, terminalColors } = useWasteTypeConfig()
	const { config } = useConfig()
	const { setComparisonWasteLoading } = useHomeLoadingState()

	const periodFilterValue = useMemo(
		() => getTimeFrame((selectedPeriod?.value as FilterTimeFrameValue) ?? defaultPeriod),
		[selectedPeriod]
	)

	const {
		data,
		isError: isTerminalError,
		isLoading,
	} = useDataWareHouse({
		endpoint: "terminal",
		terminalId: currentTerminal.id,
		filters: periodFilterValue,
	})

	const {
		data: aggregatedData,
		isError: isAggregatesError,
		isLoading: isAggregatesLoading,
	} = useDataWareHouse({
		endpoint: "terminal-aggregates",
		filters: {
			...periodFilterValue,
			commercialTerminalCategory:
				currentTerminal.commercialRealEstateCategory as CategoryNameFilters,
		},
	})

	useEffect(() => {
		setComparisonWasteLoading(isLoading || isAggregatesLoading)
	}, [setComparisonWasteLoading, isLoading, isAggregatesLoading])

	const isError = isTerminalError || isAggregatesError
	const realEstate = data?.terminal
	const wasteTypeAverages = aggregatedData?.terminalAggregates

	const colors = useMemo(
		() => ({
			value: terminalColors?.primaryColor || config?.primaryColor || "#87874E",
			average: terminalColors?.secondaryColor || config?.secondaryColor || "#DDCAB9",
		}),
		[config, terminalColors]
	)

	const barChartData = useMemo(
		() =>
			wasteTypes?.length
				? createWasteKgPerTenantBarData(realEstate, wasteTypeAverages, wasteTypes, wasteTypeConfig)
				: [],
		[wasteTypes, realEstate, wasteTypeAverages, wasteTypeConfig]
	)

	const chart = useMemo(() => {
		if (isLoading || isAggregatesLoading) {
			return (
				<div className="flex justify-center items-center min-h-full h-full">
					<LoadingIndicator />
				</div>
			)
		}

		if (isError) {
			return <EmptyView type="failToFetch" className="min-h-[300px]" />
		}

		if (!barChartData.length) {
			return <EmptyView type="noDataForInterval" className="min-h-[300px]" />
		}

		return (
			<BarChart
				legendName={realEstate?.terminalName ?? ""}
				data={barChartData}
				unit=" kg"
				showTickColors
				colors={colors}
			/>
		)
	}, [barChartData, isAggregatesLoading, isError, isLoading, realEstate, colors])

	return (
		<Card
			title={"circularityLabels:comparisonWasteTypeKG"}
			filters={filters}
			onFilterOptionChange={setActiveOptions}
			filterLocation={CardComponentFilterLocation.RIGHT}
		>
			{chart}
		</Card>
	)
}
