import { useConfig } from "api/hooks/useConfig"
import { EmptyView } from "components/EmptyView/EmptyView"
import { Card, CardComponentFilterLocation } from "components/pageCards/card"
import { getTimeFrame } from "components/pageCards/filterSort/filterTimeFrame"
import {
	createTimeFilters,
	FilterTimeFrameValue,
} from "components/pageCards/filterSort/filterCreators"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { FilterSort } from "components/pageCards/filterSort/types"
import Piechart from "components/Piechart"
import { PieChartData } from "components/Piechart/Piechart.interface"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { COLORS, getNewColor } from "pages/customer/dashboard/functions/pieChartData"
import { useEffect, useMemo, useState } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import { Terminal } from "Utils/api/datawarehouse/responseTypes"
import { TerminalAggregates, Terminal as TerminalProp } from "Utils/api/datawarehouse/types"
import { FetchedWasteTypes } from "Utils/api/sanity/types"
import { removeMoreThanTwoDecimals } from "Utils/formatFunctions"
import { useWasteTypeConfig, WasteTypeConfig } from "pages/configuration/useWasteTypeConfig"
import { useHomeLoadingState } from "pages/home/loadingState"

const preparePieChartData = (
	realEstate: Terminal | undefined,
	wasteTypes: FetchedWasteTypes,
	translationTextUnknown: string,
	translationTextOther: string,
	isMWM: boolean,
	wasteTypeConfig: WasteTypeConfig[],
	otherColor: string
): PieChartData[] => {
	if (!realEstate) return []

	let curColors = [...COLORS]
	const totalWasteWeight = realEstate.totalWasteWeight.quantity

	return (
		realEstate.wasteTypes
			?.reduce(
				(acc: PieChartData[], dwhWasteType) => {
					const customWasteType = wasteTypeConfig.find(
						wtc => wtc.wasteTypeCode === dwhWasteType.code
					)
					const wasteTypeName =
						customWasteType?.name ||
						wasteTypes.find(wasteType => wasteType.id === dwhWasteType.code)?.name ||
						`${translationTextUnknown} (${dwhWasteType.code})`

					const wasteShare = isMWM
						? dwhWasteType.occurrences / realEstate.totalOccurrences
						: dwhWasteType.weight.quantity / totalWasteWeight

					if (wasteShare < 0.005) {
						const [other, ...rest] = acc
						return [
							{
								...other,
								value: isMWM
									? dwhWasteType.occurrences
									: removeMoreThanTwoDecimals(other.value + dwhWasteType.weight.quantity),
							},
							...rest,
						]
					}

					const [color, newColors] = getNewColor(curColors)
					curColors = newColors
					return [
						...acc,
						{
							id: wasteTypeName,
							label: wasteTypeName,
							value: isMWM ? dwhWasteType.occurrences : dwhWasteType.weight.quantity,
							color: customWasteType?.color || color,
						},
					]
				},
				[{ id: "Other", label: translationTextOther, value: 0, color: otherColor }]
			)
			.map((el, i) => ({ ...el, id: `${el.id}-${i}` })) // added to mitigate cases where waste types have the same name
			.filter(el => el.value !== 0)
			.reverse() || []
	)
}

const defaultPeriod = FilterTimeFrameValue.LAST_30_DAYS

export const WasteTypeDistribution = () => {
	const { currentTerminal } = useTerminalsState()
	const [{ period: [selectedPeriod] = [] }, setActiveOptions] = useState<ActiveOptions<"period">>(
		{} as any
	)
	const { wasteTypes } = useCommonEntitiesStore()
	const { t } = useTrans()
	const { isMWM, config } = useConfig()
	const { wasteTypeConfig, terminalColors } = useWasteTypeConfig()
	const { setWasteDistributionLoading } = useHomeLoadingState()

	const filters: FilterSort[] = [createTimeFilters({ defaultValue: defaultPeriod })]

	const requestParams = useMemo(() => {
		const filters = getTimeFrame((selectedPeriod?.value as FilterTimeFrameValue) ?? defaultPeriod)

		const props: TerminalAggregates | TerminalProp = isMWM
			? {
					endpoint: "terminal-aggregates",
					filters,
			  }
			: {
					endpoint: "terminal",
					terminalId: currentTerminal.id,
					filters,
			  }

		return props
	}, [selectedPeriod, currentTerminal, isMWM])

	const { data, isError, isLoading } = useDataWareHouse(requestParams)

	useEffect(() => {
		setWasteDistributionLoading(isLoading)
	}, [setWasteDistributionLoading, isLoading])

	const realEstate = isMWM ? (data as any)?.terminalAggregates : (data as any)?.terminal
	const otherColor = terminalColors?.otherColor || config.otherColor || "#B6BB22"
	const wasteTypeDistributionData = useMemo(
		() =>
			wasteTypes?.length
				? preparePieChartData(
						realEstate,
						wasteTypes,
						t("common:unknown"),
						t("circularityLabels:otherWasteTypes"),
						isMWM,
						wasteTypeConfig,
						otherColor
				  )
				: [],
		[t, wasteTypes, realEstate, isMWM, wasteTypeConfig, otherColor]
	)

	const chart = useMemo(() => {
		if (isLoading) {
			return (
				<div className="flex justify-center items-center min-h-full h-full">
					<LoadingIndicator />
				</div>
			)
		}

		if (isError) {
			return <EmptyView type="failToFetch" className="min-h-[300px]" />
		}

		if (!wasteTypeDistributionData.length) {
			return <EmptyView type="noDataForInterval" className="min-h-[300px]" />
		}

		return (
			<Piechart
				data={wasteTypeDistributionData}
				filterLabel={t("hints:clickToSelectOrUnselectWasteTypes")}
			/>
		)
	}, [isLoading, isError, wasteTypeDistributionData, t])

	return (
		<Card
			title={"circularityLabels:wasteTypeDistribution"}
			filters={filters}
			onFilterOptionChange={setActiveOptions}
			filterLocation={CardComponentFilterLocation.RIGHT}
		>
			{chart}
		</Card>
	)
}
