import { BarDatum, ResponsiveBar } from "@nivo/bar"
import React from "react"
import { twMerge } from "tailwind-merge"
import { useTrans } from "translations"
import { formatNumberForTable, TFormatNumberSuffix } from "Utils/formatFunctions"

const measureTextWidth = (text: string, font = "11px DM Sans") => {
	// Create a temporary element
	const element = document.createElement("span")
	element.style.font = font
	element.style.visibility = "hidden" // Hide the element
	element.style.whiteSpace = "nowrap" // Ensure the text is in one line
	element.textContent = text

	document.body.appendChild(element) // Append to the document to get the width
	const width = element.offsetWidth // Get the width
	document.body.removeChild(element) // Remove the element

	return width
}

export const BarChart: React.FC<{
	data: BarDatum[]
	unit: TFormatNumberSuffix
	legendName: string
	useLabel?: boolean
	showAverage?: boolean
	showLegend?: boolean
	enableGridY?: boolean
	axisLeft?: { [k: string]: any } | null
	margin?: { [k: string]: number }
	maxValue?: number | "auto"
	showTickColors?: boolean
	colors?: { [k: string]: string }
}> = ({
	data,
	unit = "",
	legendName,
	useLabel = true,
	showAverage = true,
	showLegend = true,
	enableGridY = false,
	axisLeft = null,
	margin = { top: 0, right: 0, bottom: 25, left: 2 },
	maxValue = "auto",
	showTickColors = false,
	colors = {
		average: "#DDCAB9",
		value: "#87874E",
	},
}) => {
	const { t } = useTrans()

	const keys = ["value"]
	if (showAverage) {
		keys.push("average")
	}

	return (
		<div className="flex h-full flex-col justify-center">
			<div className={twMerge("h-[293px]", showLegend && "mb-8")}>
				<ResponsiveBar
					data={data}
					keys={keys}
					groupMode="grouped"
					indexBy="name"
					isInteractive={true}
					enableGridY={enableGridY}
					enableGridX={false}
					axisLeft={axisLeft}
					margin={margin}
					colors={({ id }) => colors[id]}
					label={v => (useLabel ? v.formattedValue : "")}
					innerPadding={2}
					axisBottom={{
						tickSize: 0,
						tickPadding: 8,
						tickRotation: 0,
						renderTick: ({ tickIndex, value, x, y }) => {
							const width = measureTextWidth(value)
							return (
								<g transform={`translate(${x}, ${y})`} style={{ opacity: 1, display: "inline" }}>
									{showTickColors && (
										<circle
											r="5"
											cx={-width / 2 - 8}
											cy="15"
											fill={data[tickIndex]?.color?.toString()}
											style={{ float: "left" }}
										/>
									)}
									<text
										textAnchor="middle"
										dominantBaseline="text-before-edge"
										transform="translate(0, 8)"
										style={{
											fontSize: 11,
											fontFamily: `"DM Sans", sans-serif`,
											fill: "#333333",
											float: "right",
										}}
									>
										{value}
									</text>
								</g>
							)
						},
					}}
					tooltipLabel={({ indexValue, data }) => String(data.fullName ?? indexValue)}
					valueFormat={value =>
						formatNumberForTable(value, { suffix: unit, numDecimals: value % 1 ? 1 : 0 })
					}
					labelSkipHeight={15}
					theme={{
						text: {
							fontFamily: `"DM Sans", sans-serif`,
						},
					}}
					maxValue={maxValue}
				/>
			</div>
			{showLegend && (
				<div className="flex items-center justify-center">
					<p className="flex items-center pr-8 font-dmSans text-xs text-black last:pr-0">
						<span
							className="mr-2 inline-flex h-1 w-8"
							style={{
								background: colors.value,
							}}
						/>
						{legendName}
					</p>
					{showAverage && (
						<p className="flex items-center pr-8 font-dmSans text-xs text-black last:pr-0">
							<span
								className="mr-2 inline-flex h-1 w-8"
								style={{
									background: colors.average,
								}}
							/>
							{t("statisticsLabels:averageForCarrotCenters")}
						</p>
					)}
				</div>
			)}
		</div>
	)
}
