import { useTerminalsState } from "States/Terminals"
import { Button } from "components/button/index"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { trpc } from "Utils/trpc"
import { SingleValue } from "react-select"
import { Translate, useTrans } from "translations"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminals } from "api/hooks/useTerminals"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { useModal } from "Contexts"
import { DiscardModal } from "components/modalContainer"
import { useGlobalAlert } from "States/globalAlert"
import { Toggle } from "components/Toggle"
import { useConfig } from "api/hooks/useConfig"
import { StyledSelect } from "components/StyledSelect"
import { sortAlphabeticallyByProperty } from "Utils/sorting"

type Props = {
	hasUnsavedChanges: boolean
	setHasUnsavedChanges: (hasChanges: boolean) => void
}

export const Building: FC<Props> = ({ hasUnsavedChanges, setHasUnsavedChanges }) => {
	const { terminals } = useTerminalsState()
	const { updateTerminal, isUpdatingTerminal } = useTerminals()
	const { wasteTypeClassificationSystems } = useCommonEntitiesStore()
	const { showModal, hideModal } = useModal()
	const { setGlobalAlert } = useGlobalAlert()
	const { isRealEstate } = useConfig()

	const [selectedTerminalId, setSelectedTerminalId] = useState<string | undefined>()

	const [permanentLogin, setPermanentLogin] = useState<boolean>(false)
	const [wasteTypeClassificationSystemId, setWasteTypeClassificationSystemId] = useState<
		string | null
	>(null)

	const { data: appConfig, refetch } = trpc.config.getAppConfig.useQuery(
		{
			terminalId: selectedTerminalId!,
		},
		{
			enabled: isRealEstate && !!selectedTerminalId,
		}
	)

	const selectedTerminal = useMemo(() => {
		return terminals.find(t => t.id === selectedTerminalId)
	}, [terminals, selectedTerminalId])

	useEffect(() => {
		const selectedTerminal = terminals.find(t => t.id === selectedTerminalId)
		if (!selectedTerminal) {
			setHasUnsavedChanges(false)
			return
		}

		const hasChangedWTCS =
			selectedTerminal?.wasteTypeClassificationSystemId !== wasteTypeClassificationSystemId
		const hasChangedPermanentLogin = appConfig?.permanentLogin !== permanentLogin
		const hasChanges = hasChangedWTCS || hasChangedPermanentLogin
		setHasUnsavedChanges(hasChanges)
	}, [
		selectedTerminalId,
		setHasUnsavedChanges,
		wasteTypeClassificationSystemId,
		terminals,
		appConfig,
		permanentLogin,
	])

	const { data: scannerServiceExists, isLoading: isWaitingForScannerService } =
		trpc.scannerServiceExists.useQuery()

	const { mutateAsync: mutateAppConfig, isLoading: isUpdatingAppConfig } =
		trpc.config.updateAppConfig.useMutation()

	const { t } = useTrans()

	const terminalOptions = terminals
		.map(terminal => ({
			label: terminal.name,
			value: terminal.id,
		}))
		.sort((a, b) => sortAlphabeticallyByProperty(a, b, "label"))

	const wasteTypeClassificationSystemOptions = useMemo(() => {
		return (
			wasteTypeClassificationSystems?.map(w => ({
				label: w.name,
				value: w.id,
			})) || []
		)
	}, [wasteTypeClassificationSystems])

	useEffect(() => {
		if (appConfig) {
			setPermanentLogin(appConfig.permanentLogin)
		}
	}, [appConfig])

	const onTerminalChange = useCallback(
		(change: SingleValue<(typeof terminalOptions)[number]>) => {
			const changeTerminal = () => {
				setSelectedTerminalId(change?.value as string)
				setWasteTypeClassificationSystemId(
					terminals.find(t => t.id === change?.value)?.wasteTypeClassificationSystemId || null
				)
			}

			if (hasUnsavedChanges) {
				showModal(
					<DiscardModal
						onCancel={() => hideModal()}
						onConfirm={() => {
							changeTerminal()
							hideModal()
						}}
					/>
				)
			} else {
				changeTerminal()
			}
		},
		[terminals, hasUnsavedChanges, showModal, hideModal]
	)

	const onWasteTypeClassificationSystemIdChange = useCallback(
		(change: SingleValue<(typeof wasteTypeClassificationSystemOptions)[number]>) => {
			setWasteTypeClassificationSystemId(change?.value ?? null)
		},
		[setWasteTypeClassificationSystemId]
	)

	const scannerAPIComponent = useMemo(() => {
		if (isWaitingForScannerService) {
			return (
				<div>
					<LoadingIndicator />
				</div>
			)
		} else if (scannerServiceExists) {
			return (
				<div>
					<Toggle
						label={t("configLabels:permanentLogin")}
						checked={permanentLogin}
						onChange={() => setPermanentLogin(!permanentLogin)}
					/>
				</div>
			)
		} else {
			return <div className="text-carrotRed">{t("hints:scannerServiceNotAvailable")}</div>
		}
	}, [isWaitingForScannerService, scannerServiceExists, permanentLogin, t])

	const onSave = useCallback(async () => {
		if (!selectedTerminalId) return
		try {
			await updateTerminal({
				id: selectedTerminalId,
				wasteTypeClassificationSystemId: wasteTypeClassificationSystemId ?? undefined,
			})
			await mutateAppConfig({
				terminalId: selectedTerminalId,
				permanentLogin,
			})
			setHasUnsavedChanges(false)
			setGlobalAlert({
				type: "success",
				message: "systemMessages:changesSaved",
			})
			refetch()
		} catch (error: Error | any) {
			console.error(error)
			setGlobalAlert({
				type: "error",
				message: "errors:failedSave",
				instructions: error?.message,
			})
		}
	}, [
		setHasUnsavedChanges,
		selectedTerminalId,
		updateTerminal,
		wasteTypeClassificationSystemId,
		mutateAppConfig,
		permanentLogin,
		refetch,
		setGlobalAlert,
	])

	const isSaving = isUpdatingAppConfig || isUpdatingTerminal

	return (
		<div className="space-y-4">
			<div className="my-4">
				<ul className="list-disc list-inside ml-2">
					<Translate i18nKey="hints:configureBuildings" children={[<li />, <li />, <li />]} />
				</ul>
			</div>
			<div className="max-w-[412px]">
				<label className="select-none">{t("configLabels:selectBuilding")}</label>
				<StyledSelect
					className="mb-4 mt-1"
					options={terminalOptions}
					value={terminalOptions.find(o => o.value === selectedTerminalId)}
					onChange={onTerminalChange}
				/>
			</div>
			{selectedTerminal && (
				<>
					<div className="bg-grey1 max-w-[412px] p-4">
						<h3 className="text-xl font-signifier mb-2">{selectedTerminal.name}</h3>
						<div className="max-w-[380px]">
							<label className="select-none">{t("formLabels:setDefaultWTCS")}</label>
							<StyledSelect
								className="mt-2 mb-4"
								isClearable
								options={wasteTypeClassificationSystemOptions}
								value={
									wasteTypeClassificationSystemOptions.find(
										o => o.value === wasteTypeClassificationSystemId
									) ?? null
								}
								onChange={onWasteTypeClassificationSystemIdChange}
							/>
						</div>
						<div className="mb-4">{scannerAPIComponent}</div>
					</div>
					<hr className="-mx-4" />
					<Button
						label="actions:save"
						disabled={!hasUnsavedChanges}
						onClick={onSave}
						loading={isSaving}
					/>
				</>
			)}
		</div>
	)
}
