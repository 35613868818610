import { isArray, mergeWith } from "lodash"
import { config, defaultQrUrl, sanityToken, site, staging } from "./devWindowParams"
import { IConfig, IPartialSiteConfig, ISite, ISiteConfig } from "./types"

// Get sitename from window.site in production else
// { site } from "./devWindowParams" in dev
const getCurrentSite = (_site: any) => ("site" in window ? (window as any).site : _site)
const getCurrentConfig = (_config: any) => ("config" in window ? (window as any).config : _config)

// Get isStaging from window.isStaging in production else
// { staging } from "./devWindowParams" in dev
const getIfIsStaging = (_isStaging: boolean) =>
	"isStaging" in window ? (window as any).isStaging : _isStaging

const getSanityToken = (_sanityToken: any) => ("sanityToken" in window ? (window as any).sanityToken : _sanityToken)

const parseOptionalBoolean = (value: boolean | string | undefined): boolean | undefined => {
	if (value === undefined) {
		return undefined
	}
	return value === "true" || value === true

}

const parseOptionalString = (value: string | undefined): string | undefined => {
	if (value === undefined || value === "undefined" || value === "null") {
		return undefined
	}
	return value
}

export const siteName = getCurrentSite(site) as ISite
export const configName = (getCurrentConfig(config) as IConfig) || (getCurrentSite(site) as ISite)
export const isStaging = getIfIsStaging(staging) as boolean
export const isLocalhost = window.location.origin.includes("localhost")
export const SANITY_TOKEN = getSanityToken(sanityToken)
export const locizeProjectId = process.env.REACT_APP_LOCIZE_PROJECT_ID ?? (window as any).locizeProjectId
export const locizeVersion = process.env.REACT_APP_LOCIZE_VERSION ?? (window as any).locizeVersion
export const useExternalCRMOverride = parseOptionalBoolean(process.env.REACT_APP_USE_EXTERNAL_CRM ?? (window as any).useExternalCRM)
export const clientTypeOverride = parseOptionalString(process.env.REACT_APP_CLIENT_TYPE ?? (window as any).clientType)
export const wtcsOverride = parseOptionalString(process.env.REACT_APP_WTCS_OVERRIDE ?? (window as any).wtcs)
export const useWasteSuctionSystemOverride = parseOptionalBoolean(process.env.REACT_APP_USE_WASTE_SUCTION_SYSTEM ?? (window as any).useWasteSuctionSystem)

export const mergeConfig = (
	_defaultConfig: ISiteConfig,
	_localConfig: IPartialSiteConfig
): ISiteConfig =>
	mergeWith(_defaultConfig, _localConfig, (_defaultValue, _localValue) => {
		if (isArray(_defaultValue)) {
			return _localValue
		}
	})

// Get sitename from window.site in production else (used for QR codes)

const getQRUrl = (_qrUrl: any) =>
	"qrUrl" in window ? (window as any)["qrUrl"] : _qrUrl

export const qrUrl = getQRUrl(defaultQrUrl) as string
