import { useModal } from "Contexts"
import { useConfig } from "api/hooks/useConfig"
import useCustomers from "api/hooks/useCustomers"
import { Page } from "components/Page"
import MultiListTable from "components/pageCards/multiListTable"
import { MultiListSectionConfig } from "components/pageCards/multiListTable/multiListSection"
import { CustomerDrawer } from "pages/customer/components/CustomerDrawer"
import { FC, useCallback, useEffect, useMemo, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useTrans } from "translations"
import { CreateTenantModal } from "./components/realEstate/CreateTenantModal"
import { NoAccessParentsForCustomersModal } from "./components/NoAccessParentsForCustomersModal"
import { isCarrotAdmin } from "Utils"
import { Header } from "components/TableWithSelect"
import { Cell } from "@tanstack/react-table"
import { CreateCustomerModal } from "./components/mwm/CreateCustomerModal"
import { FilterSort, FilterSortMenuType } from "components/pageCards/filterSort/types"
import { OPTION_ALL } from "components/pageCards/filterSort/constants"
import { useCommonEntitiesStore } from "States/commonEntities"
import { TypeEnum } from "api/hooks/useCustomers/useCustomers"
import { useMultiListInitialState } from "components/pageCards/multiListTable/multiListState"
import { AddButton } from "components/AddButton"
import { trpc } from "Utils/trpc"
import { throttle } from "throttle-debounce"
import { useTerminalsState } from "States/Terminals"

export const ManageCustomers: FC<{}> = () => {
	const {
		data: tenants,
		isLoadingCustomers,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
		sortingState,
		setSortingState,
		setTenantsFilterState,
		tenantsFilterState,
		selectedIds,
		setSelectedIds,
		allSelected,
		setAllSelected,
		nameFilter,
		setNameFilter,
	} = useCustomers()
	const { id: customerIdFromUrl } = useParams()
	const navigate = useNavigate()
	const { t } = useTrans()

	const { config, isRealEstate, isMWM } = useConfig()

	const { currentTerminal } = useTerminalsState()

	const { data: accessParents } = trpc.accessParents.getAll.useQuery({
		terminalId: isMWM ? undefined : currentTerminal?.id,
	})
	const { tenantCategories } = useCommonEntitiesStore()

	const selectedCustomers = useMemo(
		() => tenants?.filter(customer => selectedIds.includes(customer.id)) ?? [],
		[tenants, selectedIds]
	)

	const typeRender = useCallback(
		({ cell }: { cell: Cell<any, unknown> }) => {
			return t(`entities:customerType${(cell.getValue() as string).toUpperCase()}`)
		},
		[t]
	)

	const tableHeaders: Header[] = [
		{ key: "name", title: "entities:customerReference", sortable: true },
		config.useExternalCRM && { key: "crmId", title: "drawerLabels:crmId", sortable: false },
		isRealEstate && {
			key: "category",
			title: "statisticsLabels:customerCategory",
			sortable: false,
		},
		isMWM && {
			key: "type",
			title: "statisticsLabels:customerType",
			renderFunction: typeRender,
			sortable: false,
		},
	].filter(Boolean) as Header[]

	const { showModal } = useModal()

	const accessParentsLength = useMemo(() => accessParents?.length, [accessParents])

	const createCustomer = useCallback(() => {
		if (accessParentsLength === 0) {
			showModal(<NoAccessParentsForCustomersModal />)
			return
		}
		if (isRealEstate) {
			showModal(<CreateTenantModal />)
		} else {
			return showModal(<CreateCustomerModal />)
		}
	}, [showModal, accessParentsLength, isRealEstate])

	useEffect(() => {
		if (
			hasNextPage &&
			customerIdFromUrl &&
			!tenants.find(customer => customer.id === customerIdFromUrl)
		) {
			fetchNextPage()
		}
	}, [hasNextPage, customerIdFromUrl, tenants, fetchNextPage])

	const customerTypeFilter: FilterSort = useMemo(
		() => ({
			type: "filter",
			menuType: FilterSortMenuType.Select,
			options: [
				{
					option: "filterLabels:allCustomerTypes",
					value: "all",
					translate: true,
				},
				...Object.values(TypeEnum)
					.filter(key => (!config?.useExternalCRM ? !key.toLowerCase().includes("parent") : true))
					.map(key => ({
						option: t(`entities:customerType${key.toUpperCase()}`),
						value: key.toLowerCase(),
					})),
			],
			title: "filterLabels:customerTypes",
			id: "type",
			defaultValue: OPTION_ALL,
		}),
		[t, config]
	)

	const tenantCategoryFilter: FilterSort = useMemo(
		() => ({
			type: "filter",
			menuType: FilterSortMenuType.Select,
			options: [
				{
					option: "filterLabels:allCustomerTypes",
					value: "all",
					translate: true,
				},
				...(tenantCategories || []).map(category => ({
					option: category.name,
					value: category.id,
				})),
			],
			title: "filterLabels:tenantCategories",
			id: "category",
			defaultValue: OPTION_ALL,
			isMultiselect: true,
		}),
		[tenantCategories]
	)

	const accessParentFilter: FilterSort = useMemo(
		() => ({
			type: "filter",
			menuType: FilterSortMenuType.Select,
			options: [
				{
					option: "filterLabels:allAccessParents",
					value: "all",
					translate: true,
				},
				...(accessParents || []).map(ap => ({
					option: ap.name,
					value: ap.id,
				})),
			],
			title: "entities:accessParents",
			id: "accessParentId",
			defaultValue: OPTION_ALL,
			isMultiselect: true,
		}),
		[accessParents]
	)

	const searchDebounced = useRef(
		throttle(500, (value?: string) => {
			setNameFilter(value)
		})
	)

	const onSearch = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value
			searchDebounced.current(value || undefined)
		},
		[searchDebounced]
	)

	const filters = useMemo(
		() => [isMWM ? customerTypeFilter : tenantCategoryFilter, accessParentFilter],
		[isMWM, customerTypeFilter, tenantCategoryFilter, accessParentFilter]
	)

	const customersSection: MultiListSectionConfig<typeof tenants> = useMemo(
		() => ({
			type: "table",
			title: "",
			headers: tableHeaders,
			data: tenants,
			isLoading: isLoadingCustomers && !isFetchingNextPage,
			emptyViewType: nameFilter ? "noCustomerResults" : "noCustomers",
			onRowClick: row => {
				const searchParams = window.location.search
				const rowId = row["id"] as string
				if (selectedIds.includes(rowId)) {
					setSelectedIds(selectedIds.filter(id => id !== rowId))
					navigate(`/customer/manage${searchParams}`)
				} else {
					setSelectedIds([rowId])
					const newPath = `/customer/manage/${rowId}${searchParams}`
					navigate(newPath)
				}
			},
			isRowSelected: ({ row }) => selectedIds.includes(row["id"]),
			customButton:
				isRealEstate || !config?.useExternalCRM ? (
					<AddButton onClick={createCustomer} />
				) : undefined,
			drawer: {
				content: (
					<CustomerDrawer
						selectedCustomers={selectedCustomers}
						areAllSelected={allSelected}
						filterState={tenantsFilterState}
						sortingState={sortingState}
						nameFilter={nameFilter}
					/>
				),
			},
			hasMultipleSelectFunctionality: true,
			labelSingle: "entities:customer",
			labelMultiple: "entities:customers",
			showExportButton: false,
			pagination: {
				fetchNextPage,
				hasNextPage,
				isFetchingNextPage,
			},
			updateSorting: setSortingState,
			filters,
			onFilterOptionChange: setTenantsFilterState,
			useSearchParams: true,
			onAllSelected: setAllSelected,
			searchProps: {
				placeholder: t("formLabels:customerReferenceSearchPlaceholder"),
				onChange: onSearch,
			},
		}),
		[
			selectedIds,
			setSelectedIds,
			createCustomer,
			selectedCustomers,
			tenants,
			navigate,
			isLoadingCustomers,
			tableHeaders,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			setSortingState,
			setTenantsFilterState,
			isRealEstate,
			allSelected,
			config,
			setAllSelected,
			filters,
			sortingState,
			tenantsFilterState,
			t,
			nameFilter,
			onSearch,
		]
	)

	const goToImport = useCallback(() => {
		if (accessParentsLength === 0) {
			showModal(<NoAccessParentsForCustomersModal />)
			return
		} else {
			navigate("/customer/manage/import")
		}
	}, [navigate, accessParentsLength, showModal])

	const initialState: useMultiListInitialState = {
		isDrawerOpen: !!selectedCustomers.length || !!customerIdFromUrl,
	}

	const showImportButton = useMemo(
		() => (isRealEstate && isCarrotAdmin()) || (isMWM && !config?.useExternalCRM),
		[isRealEstate, config, isMWM]
	)

	return (
		<Page
			fullHeight
			title="sidebarLabels:manageCustomers"
			button={
				showImportButton
					? {
							label: "importLabels:importCustomers",
							onClick: goToImport,
							type: "button",
							color: "primary",
					  }
					: undefined
			}
		>
			<MultiListTable {...{ sections: [customersSection], initialState }} />
		</Page>
	)
}
