import { QRCodeSVG } from "qrcode.react"

export const DOMAIN = "carrot.tech"
export const DWH_PROD_DOMAIN = "dwh"
export const DWH_TEST_DOMAIN = "dwhtest"
export const QR_OPTIONS: Partial<React.ComponentProps<typeof QRCodeSVG>> = {
	size: 200,
	level: "Q",
}

export const EXTERNAL_ID_KEY = "ImportID"

export const EXCEL_FORMAT = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

export const CLIPPED_BUTTON_STYLE =
	"[clip-path:polygon(4px_0%,0%_4px,0%_calc(100%-4px),4px_100%,calc(100%-4px)_100%,100%_calc(100%-4px),100%_4px,calc(100%-4px)_0%);]"

export const IDENTITIES_KEY = "identities"
export const PROPERTIES_KEY = "properties"
export const POINTS_HISTORY_KEY = "points"
export const EXTERNAL_KEYS_KEY = "externalKeys"
export const SMART_INTEGRATIONS_KEY = "smartIntegrations"
export const CONTACT_PEOPLE_KEY = "contactPeople"

// access point properties
export const ACCESS_POINT_NAME_KEY = "name"
export const ACCESS_POINT_TYPE_KEY = "type"
export const ACCESS_POINT_EMAIL_KEY = "operationsContactEmail"
export const REAL_ESTATE_CATEGORY_KEY = "commercialRealEstateCategory"
export const ACCESS_POINT_AREA_KEY = "area"

export const ACCESS_POINT_COORDINATES = "decimalDegrees"
export const ACCESS_POINT_STATUS_KEY = "status"
export const ACCESS_POINT_ADDRESS_KEY = "geoLocationName"

export const ACCESS_POINT_WTCS_ID_KEY = "wasteTypeClassificationSystemId"
export const ACCESS_POINT_WTCS_LEGACY_KEY = "fractionDiscriminator"
export const ACCESS_POINT_WTCS_KEY = "classificationSystem"
export const ACCESS_POINT_FRACTION_KEY = "fraction"
export const ACCESS_POINT_FRACTION_DESC_KEY = "fractionDesc"

export const MUNICIPALITY_KEY = "Municipality"
export const CLIENT_KEY = "Oppdragsgiver"

// customer properties
export const CUSTOMER_ADDRESS_KEY = "geoLocationName"
export const CUSTOMER_POSTAL_CODE_KEY = "postalCode"
export const CUSTOMER_POSTAL_REGION_KEY = "postal"
